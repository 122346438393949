import { Row, Image } from "antd";

const PlasticGarbagePart = () => {
  return (
    <Row justify="center">
      <Image src="./assets/content/plastic-garbage-bag1.jpg" preview={false} />
      <Image src="./assets/content/plastic-garbage-bag2.jpg" preview={false} />
    </Row>
  );
};

export default PlasticGarbagePart;
