import { Col, Grid, Image, Row, Select } from "antd";
import MenuItem from "../common/MenuItem";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { languageContstants } from "../../language";
import { setSelectedLanguage, store } from "../../store";

const { useBreakpoint } = Grid;

const Navbar = () => {
  const selectedLanguage = useSelector((store: any) => store.selectedLanguage);
  const selectedContent = useSelector(
    (store: any) => languageContstants[store.selectedLanguage]
  );

  const screens = useBreakpoint();
  const navigate = useNavigate();

  return (
    <Row
      justify="center"
      align="middle"
      style={{ backgroundColor: "#fff", minHeight: 90 }}
    >
      <Col style={{ height: "100%" }} xs={22} lg={20}>
        <Row align="middle" style={{ height: "100%" }}>
          <Col style={{ padding: "16px 0px" }} flex={300}>
            <Row justify={screens.lg ? "start" : "center"}>
              <Image
                style={{ cursor: "pointer" }}
                width={300}
                height={60}
                src="/logo.png"
                preview={false}
                onClick={() => navigate("/")}
              />
            </Row>
          </Col>
          <Col flex="auto">
            <Row justify="space-between" align="middle">
              <MenuItem
                to="/"
                title={selectedContent.mainPage}
                color="#009933"
              />
              <MenuItem
                to="/"
                scrollTo={selectedContent.categoriesId}
                title={selectedContent.categories}
                color="#009933"
              />
              <MenuItem
                to="/"
                scrollTo={selectedContent.contactId}
                title={selectedContent.contact}
                color="#009933"
              />
              <Select
                value={selectedLanguage}
                onSelect={(selected) => {
                  store.dispatch(setSelectedLanguage(selected));
                }}
                options={Object.keys(languageContstants).map((language) => ({
                  label: language.toUpperCase(),
                  value: language,
                }))}
              />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Navbar;
