import "./index.css";
import "react-toastify/dist/ReactToastify.min.css";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LandingLayout from "./components/layout/LandingLayout";
import MainPart from "./components/part/MainPart";
import { Provider } from "react-redux";
import { store } from "./store";
import RecycleOperationsPart from "./components/part/RecycleOperationsPart";
import CompressorsPart from "./components/part/CompressorsPart";
import GeneralTradePart from "./components/part/GeneralTradePart";
import { isLoggedIn } from "./helpers/AuthHelper";
import AdminPage from "./pages/AdminPage";
import LoginPage from "./pages/LoginPage";
import { ToastContainer } from "react-toastify";
import NotFoundPart from "./components/part/common/NotFoundPart";
import ScrapTirePart from "./components/part/sub/ScrapTirePart";
import PlasticGarbagePart from "./components/part/sub/PlasticGarbagePart";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Provider store={store}>
        <LandingLayout />
      </Provider>
    ),
    children: [
      {
        path: "/",
        element: <MainPart />,
      },
      ...["/geri-donusum-hizmetleri", "/recycle-operations"].map((path) => ({
        path: path,
        element: <RecycleOperationsPart />,
      })),
      ...["/genel-ticaret", "/general-trade"].map((path) => ({
        path: path,
        element: <GeneralTradePart />,
      })),
      ...["/kompressorler", "/compressors"].map((path) => ({
        path: path,
        element: <CompressorsPart />,
      })),
      ...["/hurda-lastik", "/scrap-tire"].map((path) => ({
        path: path,
        element: <ScrapTirePart />,
      })),
      ...[
        "/plastik-cop-torbasi-ve-kompozit-paneller",
        "/plastic-garbage-bag-and-composite-panels",
      ].map((path) => ({
        path: path,
        element: <PlasticGarbagePart />,
      })),
      {
        path: "*",
        element: <NotFoundPart />,
      },
    ],
  },
  {
    path: "/admin",
    element: isLoggedIn() ? <AdminPage /> : <LoginPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <RouterProvider router={router} />
    <ToastContainer />
  </>
);
