import { createSlice, createStore } from "@reduxjs/toolkit";

const initialState = {
  selectedLanguage: "en",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setSelectedLanguage: (state: any, action) => {
      state.selectedLanguage = action.payload;
    },
  },
});

export const { setSelectedLanguage } = languageSlice.actions;
export const store = createStore(languageSlice.reducer);
