import api from "./AxiosConfig";
import { getCrudApi } from "./CrudApi";

export const SlideCrudApi = getCrudApi("slides");

export const AuthApi = {
  login: (username: string, password: string) => {
    return api.post("login", { username: username, password: password });
  },
};
