import { Col, Row, Image, Card } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { contentContstants } from "../../../language";
import { useEffect } from "react";

const ScrapTirePart = () => {
  const type = "scrapTire";
  const navigate = useNavigate();

  const selectedLanguage = useSelector((store: any) => store.selectedLanguage);
  const selected = useSelector(
    (store: any) => contentContstants[store.selectedLanguage][type]
  );

  useEffect(() => {
    navigate(contentContstants[selectedLanguage][type]["path"]);
  }, [navigate, selectedLanguage]);

  return (
    <div
      style={{
        backgroundSize: 25,
        backgroundImage: "url('./assets/content/recycle-pattern.jpg')",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backgroundBlendMode: "lighten",
      }}
    >
      <Row justify="center">
        <Image
          src="./assets/content/scrap-tire-composition.png"
          preview={false}
        />
      </Row>
      <Row justify="center" style={{ paddingBottom: 64 }}>
        <Col xs={23} md={22}>
          <br />
          <br />
          <Row justify="center">
            <h1>{selected.name}</h1>
          </Row>
          <br />
          <br />
          <Row justify="center" style={{ paddingBottom: 16 }}>
            <Image
              preview={false}
              width={240}
              height={160}
              src="./assets/content/smart-rr-logo.jpg"
            />
          </Row>
          <br />
          <br />
          <Row justify="center">{selected.companyDescription}</Row>
          <br />
          <br />
          <Row justify="space-between" align="middle">
            <Col sm={24} lg={4}>
              <Image
                width={150}
                height={150}
                src="./assets/content/define-img.jpg"
              />
            </Col>
            <Col sm={24} lg={20}>
              {selected.certificateDescription}
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            {selected.definitions.map((definition: any) => (
              <Col xs={24} lg={8}>
                <Row justify="center">
                  <h2>{definition.title}</h2>
                </Row>
                <Row>
                  <ul>
                    {definition.contents.map((content: any) => (
                      <li>{content}</li>
                    ))}
                  </ul>
                </Row>
              </Col>
            ))}
          </Row>
          <br />
          <br />
          <Row justify="center">
            <h2>{selected.ourServices.title}</h2>
          </Row>
          <Row justify="space-around" gutter={[16, 16]}>
            {selected.ourServices.services.map((service: any) => (
              <Col>
                <Card
                  style={{ width: 250, minHeight: 400 }}
                  cover={
                    <img
                      style={{ width: 250, height: 250, objectFit: "cover" }}
                      src={service.imgSrc}
                      alt={service.imgAlt}
                    />
                  }
                >
                  <Card.Meta
                    style={{ textAlign: "center" }}
                    description={service.description}
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <br />
          <br />
          <Row justify="center">
            <h2>{selected.ourPersonels.title}</h2>
          </Row>
          <Row justify="space-around" gutter={[24, 24]}>
            {selected.ourPersonels.personels.map((personel: any) => (
              <Col>
                <Card
                  style={{ width: 250, minHeight: 400 }}
                  cover={
                    <Image
                      style={{ width: 250, height: 250, objectFit: "cover" }}
                      preview={false}
                      src={personel.imgSrc}
                      alt={personel.imgAlt}
                    />
                  }
                >
                  <Card.Meta
                    style={{ textAlign: "center" }}
                    description={
                      <>
                        {personel.descriptions.map((description: string) => (
                          <>
                            <span>{description}</span> <br />
                          </>
                        ))}
                      </>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ScrapTirePart;
