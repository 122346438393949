import { Col, Grid, Row } from "antd";
import { useSelector } from "react-redux";
import { languageContstants } from "../../language";
import {
  MailOutlined,
  PhoneOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import MenuItem from "../common/MenuItem";

const { useBreakpoint } = Grid;

const Footer = () => {
  const screens = useBreakpoint();
  const selectedLanguage = useSelector((store: any) => store.selectedLanguage);
  const selectedContent = useSelector(
    (store: any) => languageContstants[store.selectedLanguage]
  );

  return (
    <Row
      justify="center"
      style={{ backgroundColor: "#009933", color: "#f0f0f0" }}
    >
      <Col span={24}>
        <Row style={{ padding: "24px 0px", minHeight: 250 }}>
          <Col xs={{ offset: 2, span: 20 }} lg={{ offset: 2, span: 6 }}>
            <h2>{selectedContent.informationTitle}</h2>
            <p>{selectedContent.information}</p>
          </Col>
          <Col xs={{ offset: 2, span: 20 }} lg={{ offset: 1, span: 6 }}>
            <h2>{selectedContent.siteMap}</h2>
            <MenuItem
              to="/"
              className="bottom-menu-item"
              title={selectedContent.mainPage}
            />
            <br />
            <MenuItem
              to="/"
              scrollTo={selectedContent.categoriesId}
              className="bottom-menu-item"
              title={selectedContent.categories}
            />
            <br />
            <MenuItem
              to="/"
              scrollTo={selectedContent.contactId}
              className="bottom-menu-item"
              title={selectedContent.contact}
            />
          </Col>
          <Col xs={{ offset: 2, span: 20 }} lg={{ offset: 1, span: 6 }}>
            <h2>{selectedContent.contactUpperCase}</h2>
            <p>
              <PushpinOutlined /> CHRISTOPH STREET 40 ALTSTADT-NORD 50670
              KÖLN-GERMANY
            </p>
            <p>
              <MailOutlined /> info@nitroxgreen.com
            </p>
            <p>
              <PhoneOutlined /> +49 (0)1635720314
            </p>
            <p>
              <PhoneOutlined /> +90 533 363 8008
            </p>
            <p>
              <PhoneOutlined /> +90 545 576 7077
            </p>
          </Col>
        </Row>
      </Col>
      <Col span={20}>
        <Row
          justify="center"
          align="middle"
          style={{ height: 42, marginBottom: screens.xs ? 8 : 0 }}
        >
          {languageContstants[selectedLanguage]["copyright"]}
        </Row>
      </Col>
    </Row>
  );
};

export default Footer;
