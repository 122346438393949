import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { categoryContstants } from "../../language";
import { Card, Col, Row } from "antd";

const CompressorsPart = () => {
  const type = "compressors";
  const navigate = useNavigate();

  const selectedLanguage = useSelector((store: any) => store.selectedLanguage);
  const selected = useSelector(
    (store: any) => categoryContstants[store.selectedLanguage][type]
  );
  const selectedContent = useSelector(
    (store: any) => categoryContstants[store.selectedLanguage][type]["content"]
  );

  useEffect(() => {
    navigate(categoryContstants[selectedLanguage][type]["path"]);
  }, [navigate, selectedLanguage]);

  return (
    <Row
      style={{
        padding: 64,
        minHeight: 500,
        backgroundImage: "url('" + selected.backgroundImage + "')",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        backgroundBlendMode: "lighten",
      }}
      justify="center"
      gutter={[24, 24]}
    >
      <Col span={24}>
        <Row justify="center">
          <h2>{selected.name}</h2>
        </Row>
      </Col>
      {selectedContent.subCategories.map((subCategory: any, index: number) => (
        <Col key={index}>
          <Card
            hoverable
            onClick={() => window.open(subCategory.link)}
            style={{ width: 240 }}
            cover={<img alt={subCategory.imgAlt} src={subCategory.imgSrc} />}
          >
            <Card.Meta
              style={{ textAlign: "center" }}
              description={subCategory.title}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default CompressorsPart;
