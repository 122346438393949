import Cookies from "js-cookie";

export const isLoggedIn = () => Boolean(Cookies.get("token"));

export const login = (data: any) => {
  Cookies.set("token", data.token, { expires: 365 });
  window.location.reload();
};

export const logout = () => {
  Cookies.remove("token");
  window.location.replace(window.location.origin);
};
