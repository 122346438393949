import { Image, Row } from "antd";

const NotFoundPart = () => {
  return (
    <Row justify="center" align="middle" style={{ padding: 32 }}>
      <Image width="50%" height="50%" preview={false} src="/assets/404.jpg" />
    </Row>
  );
};

export default NotFoundPart;
