import { Button, Card, Form, Input, Row } from "antd";
import { useState } from "react";
import { AuthApi } from "../client/Api";
import { login } from "../helpers/AuthHelper";
import { required, min } from "../helpers/FormRuleHelper";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Card
        style={{ width: "600px", borderRadius: "5px" }}
        title={
          <div style={{ textAlign: "center" }}>
            <h2>
              <b>NitroxGreen Yönetim Paneli</b>
            </h2>
          </div>
        }
      >
        <Form
          autoComplete="off"
          size="large"
          onFinish={(values: any) => {
            setLoading(true);
            AuthApi.login(values.username, values.password)
              .then((response: any) => login(response.data))
              .finally(() => setLoading(false));
          }}
        >
          <Form.Item name="username" rules={[required, min(3)]}>
            <Input
              style={{ borderRadius: "5px" }}
              placeholder="Kullanıcı Adı"
            />
          </Form.Item>
          <Form.Item name="password" rules={[required, min(4)]}>
            <Input.Password
              style={{ borderRadius: "5px" }}
              placeholder="Şifre"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%", borderRadius: "5px" }}
            >
              Giriş Yap
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Row>
  );
};

export default LoginPage;
