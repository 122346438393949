import { Button, Col, Form, Image, Input, Row, TableColumnsType } from "antd";
import { SlideCrudApi } from "../client/Api";
import ChunkUpload, {
  getValueFromEvent,
} from "../components/common/ChunkUpload";
import CrudTable from "../components/crud/CrudTable";
import { logout } from "../helpers/AuthHelper";
import { required, min, max } from "../helpers/FormRuleHelper";

const AdminPage = () => {
  const columns: TableColumnsType<any> = [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Resim",
      dataIndex: "image",
      key: "image",
      render: (value: any) => (
        <Image
          width={40}
          height={40}
          src={process.env.REACT_APP_UPLOAD_URL + value.uid}
        />
      ),
    },
    { title: "Alt bilgi", dataIndex: "alt", key: "alt" },
    { title: "Başlık (TR)", dataIndex: "titleTr", key: "titleTr" },
    { title: "Açıklama (TR)", dataIndex: "descTr", key: "descTr" },
    { title: "Başlık (EN)", dataIndex: "titleEn", key: "titleEn" },
    { title: "Açıklama (EN)", dataIndex: "descEn", key: "descEn" },
    { title: "Başlık (DE)", dataIndex: "titleDe", key: "titleDe" },
    { title: "Açıklama (DE)", dataIndex: "descDe", key: "descDe" },
  ];

  const formItems = (
    <>
      <Form.Item
        label="Resim"
        name="image"
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}
        required
      >
        <ChunkUpload maxCount={1} accept=".jpg, .jpeg , .png" />
      </Form.Item>
      <Form.Item
        label="Alt Bilgi"
        name="alt"
        rules={[required, min(2), max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Başlık (TR)" name="titleTr" rules={[min(2), max(50)]}>
        <Input />
      </Form.Item>
      <Form.Item label="Açıklama (TR)" name="descTr" rules={[min(2), max(300)]}>
        <Input />
      </Form.Item>
      <Form.Item label="Başlık (EN)" name="titleEn" rules={[min(2), max(50)]}>
        <Input />
      </Form.Item>
      <Form.Item label="Açıklama (EN)" name="descEn" rules={[min(2), max(300)]}>
        <Input />
      </Form.Item>
      <Form.Item label="Başlık (DE)" name="titleDe" rules={[min(2), max(50)]}>
        <Input />
      </Form.Item>
      <Form.Item label="Açıklama (DE)" name="descDe" rules={[min(2), max(300)]}>
        <Input />
      </Form.Item>
    </>
  );

  return (
    <Row justify="center" align="middle" style={{ paddingTop: 64 }}>
      <Col span={18}>
        <Row justify="end">
          <Button danger onClick={() => logout()}>
            Çıkış Yap
          </Button>
        </Row>
        <br />
        <Row>
          <div style={{ width: "100%" }}>
            <CrudTable
              entityLabel="Slayt"
              api={SlideCrudApi}
              columns={columns}
              addFormItems={formItems}
              editFormItems={formItems}
              beforeAddOperation={(values) => {
                values.image =
                  values.image &&
                  values.image[0] &&
                  values.image[0]?.response?.id
                    ? "/api/media/" + values.image[0].response.id
                    : null;
                return values;
              }}
              beforeEditOperation={(values) => {
                if (values.image && values.image[0]) {
                  if (values.image[0]?.response?.id)
                    values.image = "/api/media/" + values.image[0].response.id;
                  if (values.image[0]["@id"])
                    values.image = values.image[0]["@id"];
                } else {
                  values.image = null;
                }

                return values;
              }}
              setEditFields={(row) => {
                return { image: row.image ? [row.image] : null };
              }}
            />
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default AdminPage;
