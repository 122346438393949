import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { logout } from "../helpers/AuthHelper";

const baseUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: baseUrl,
  responseType: "json",
});

api.interceptors.request.use(
  (config: any) => {
    const accessToken = Cookies.get("token");
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error: any) => Promise.reject(error)
);

api.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    let errorMessage = "";
    if (error.response.data.errorCode === "SB-403") {
      errorMessage = error.response.data.message;
      logout();
    } else if (error.response.status === 422) {
      error.response.data.violations.forEach((violation: any) => {
        errorMessage += violation.message + "\n";
      });
    } else if (error.response.data["hydra:description"]) {
      errorMessage = error.response.data["hydra:description"];
    } else {
      errorMessage = error.response.data.message;
    }
    toast.error(errorMessage);
    return Promise.reject(error);
  }
);

export default api;
