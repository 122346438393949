import { Button, ConfigProvider } from "antd";
import { useNavigate } from "react-router";

const MenuItem = (props: any) => {
  const navigate = useNavigate();

  return (
    <ConfigProvider theme={{ token: { colorInfo: props.color ?? "#fff" } }}>
      <Button
        className={props.className ?? "menu-item"}
        type="link"
        onClick={() => {
          navigate(props.to);
          if (props.scrollTo) {
            setTimeout(
              () => document.getElementById(props.scrollTo)?.scrollIntoView(),
              500
            );
          }
        }}
      >
        {props.title}
      </Button>
    </ConfigProvider>
  );
};

export default MenuItem;
