export const languageContstants = {
  en: {
    mainPage: "Main",
    categories: "Categories",
    categoriesId: "categories",
    contact: "Contact",
    contactId: "contact",
    contactUpperCase: "CONTACT",
    informationTitle: "INFORMATION",
    information:
      "All rights reserved on behalf of nitroxgreen.com. Copying, reproducing, distributing or republishing the site content physically or electronically without written permission may result in legal liability unless otherwise stated. You can contact us by phone or e-mail for more information.",
    siteMap: "SITE MAP",
    copyright: "nitroxgreen.com © 2024 Copyright - All Rights Reserved",
  },
  de: {
    mainPage: "Main",
    categories: "Kategorien",
    categoriesId: "categories",
    contact: "Kontakt",
    contactId: "contact",
    contactUpperCase: "KONTAKT",
    informationTitle: "INFORMATION",
    information:
      "Alle Rechte vorbehalten im Namen von nitroxgreen.com. Das Kopieren, Vervielfältigen, Verteilen oder Wiederveröffentlichen des Seiteninhalts in physischer oder elektronischer Form ohne schriftliche Erlaubnis kann zu rechtlicher Haftung führen, sofern nicht anders angegeben. Für weitere Informationen können Sie uns per Telefon oder E-Mail kontaktieren.",
    siteMap: "KARTE",
    copyright: "nitroxgreen.com © 2024 Copyright - Alle Rechte vorbehalten",
  },
} as any;

export const categoryContstants = {
  en: {
    airGas: {
      name: "NITROGEN / OXYGEN GENERATORS / HEATED-HEATLESS ADSORPTION DRYERS",
      color: "#ee8448cc",
      src: "./assets/categories/air-gas.png",
      alt: "air gas solutions image",
      isOtherLink: true,
      path: "https://www.nitroxtec.com/en_GB",
    },
    recycleOperation: {
      name: "RECYCLE OPERATIONS",
      color: "#79b226cc",
      src: "./assets/categories/recycle.png",
      alt: "recycle operations image",
      backgroundImage: "./assets/categories/recycle-bg.jpg",
      path: "/recycle-operations",
      content: {
        subCategories: [
          {
            title: (
              <>
                SCRAP TIRE <br /> BUSINESS
              </>
            ),
            path: "/scrap-tire",
            imgSrc: "./assets/subcategories/scrap-tire.png",
            imgAlt: "scraptire image",
          },
          {
            title: (
              <>
                PLASTIC GARBAGE BAGS <br /> COMPOSITE PANELS
              </>
            ),
            path: "/plastic-garbage-bag-and-composite-panels",
            imgSrc: "./assets/subcategories/plastic-garbage.png",
            imgAlt: "plastic garbage bag and composite panels image",
          },
        ],
      },
    },
    compressors: {
      name: "COMPRESSORS",
      color: "#34a8c5cc",
      src: "./assets/categories/compressors.png",
      alt: "compressors image",
      backgroundImage: "./assets/categories/compressor-bg.jpg",
      path: "/compressors",
      content: {
        subCategories: [
          {
            title: "AYKOM SCREW AIR COMPRESSORS",
            link: "https://www.aykom.com/",
            imgSrc: "./assets/subcategories/aykom-logo.png",
            imgAlt: "aykom image",
          },
          {
            title: "ALKIN HIGH PRESSURE BOOSTER COMPRESSORS",
            link: "https://alkin-compressors.com/",
            imgSrc: "./assets/subcategories/alkin-logo.png",
            imgAlt: "alkin image",
          },
        ],
      },
    },
    generalTrade: {
      name: "INDUSTRIAL MACHINERY",
      color: "#89c9b4cc",
      src: "./assets/categories/general-trade.png",
      alt: "general trade image",
      backgroundImage: "./assets/categories/compressor-bg.jpg",
      path: "/general-trade",
      content: {
        subCategories: [
          {
            title:
              "MACHINERY: CNC FIBER AND PLASMA LASER CUTTING, PRESS BRAKES",
            link: "https://www.ermaksan.com.tr",
            imgSrc: "./assets/subcategories/ermaksan-logo.jpg",
            imgAlt: "ermaksan image",
          },
          {
            title: "DIESEL ELECTRIC GENERATORS",
            link: "https://www.genpark.com.tr",
            imgSrc: "./assets/subcategories/genpark-logo.png",
            imgAlt: "diesel image",
          },
          // {
          //   title: "ANIMAL FOOD (CAT AND DOG)",
          //   link: "#",
          //   imgSrc: "./assets/subcategories/animal-food-logo.png",
          //   imgAlt: "animal food image",
          // },
        ],
      },
    },
  },
  de: {
    airGas: {
      name: "STICKSTOFF-/SAUERSTOFFGENERATOREN / BEHEIZT-THERMOLOSE ADSORPTIONSTROCKNER",
      color: "#ee8448cc",
      src: "./assets/categories/air-gas.png",
      alt: "air gas solutions image",
      isOtherLink: true,
      path: "https://www.nitroxtec.com/de",
    },
    recycleOperation: {
      name: "RECYCLINGVERFAHREN",
      color: "#79b226cc",
      src: "./assets/categories/recycle.png",
      alt: "recycle operations image",
      backgroundImage: "./assets/categories/recycle-bg.jpg",
      path: "/recycle-operations",
      content: {
        subCategories: [
          {
            title: (
              <>
                SCHROTTREIFEN <br /> BUSINESS
              </>
            ),
            path: "/scrap-tire",
            imgSrc: "./assets/subcategories/scrap-tire.png",
            imgAlt: "scraptire image",
          },
          {
            title: (
              <>
                KUNSTSTOFFMÜLLSÄCKE <br /> KOMPOSITPLATTEN
              </>
            ),
            path: "/plastic-garbage-bag-and-composite-panels",
            imgSrc: "./assets/subcategories/plastic-garbage.png",
            imgAlt: "plastic garbage bag and composite panels image",
          },
        ],
      },
    },
    compressors: {
      name: "KOMPRESSOREN",
      color: "#34a8c5cc",
      src: "./assets/categories/compressors.png",
      alt: "compressors image",
      backgroundImage: "./assets/categories/compressor-bg.jpg",
      path: "/compressors",
      content: {
        subCategories: [
          {
            title: "AYKOM SCHRAUBENLUFTKOMPRESSOREN",
            link: "https://www.aykom.com/",
            imgSrc: "./assets/subcategories/aykom-logo.png",
            imgAlt: "aykom image",
          },
          {
            title: "ALKIN HOCHDRUCK-KOMPRESSOREN",
            link: "https://alkin-compressors.com/",
            imgSrc: "./assets/subcategories/alkin-logo.png",
            imgAlt: "alkin image",
          },
        ],
      },
    },
    generalTrade: {
      name: "INDUSTRIEMASCHINEN",
      color: "#89c9b4cc",
      src: "./assets/categories/general-trade.png",
      alt: "general trade image",
      backgroundImage: "./assets/categories/compressor-bg.jpg",
      path: "/general-trade",
      content: {
        subCategories: [
          {
            title:
              "MASCHINENPARK: CNC-FASER- UND PLASMALASERSCHNEIDEN, ABKANTPRESSEN",
            link: "https://www.ermaksan.com.tr",
            imgSrc: "./assets/subcategories/ermaksan-logo.jpg",
            imgAlt: "ermaksan image",
          },
          {
            title: "DIESELELEKTRISCHE GENERATOREN",
            link: "https://www.genpark.com.tr",
            imgSrc: "./assets/subcategories/genpark-logo.png",
            imgAlt: "diesel image",
          },
          // {
          //   title: "TIERFUTTER (KATZE UND HUND)",
          //   link: "#",
          //   imgSrc: "./assets/subcategories/animal-food-logo.png",
          //   imgAlt: "animal food image",
          // },
        ],
      },
    },
  },
} as any;

export const contentContstants = {
  en: {
    scrapTire: {
      name: "SCRAP TIRE BUSINESS",
      certificateDescription:
        "Certificate of Registration under Regulations 2011 Regulation authority National Customer Contact Centre 99 Parkway Avenue, Sheffield, S9 4WF Telephone number 03708 506506 The Environment Agency certify that the following information is entered in the register which they maintain under regulation 28 of the Waste (England and Wales) Regulations 2011. Carriers' details Name of registered carrier SMART RESOURCES RECYCLING LIMITED Registered as An upper tier waste carrier, broker and dealer Registration number CBDU139128 Address of place of business 33, EDWARD STREET,RUSTHALL, ROYAL TUNBRIDGE WELLS,TN4 8RP Telephone number 01892670801 Date of registration 15 September 2022 Expiry date of registration (unless revoked) 13 October 2025",
      companyDescription:
        "UK based Smart Resources Recycling Limited is the partner of Nitrox-Green Company for recycling operations.",
      definitions: [
        {
          title: "Why Choose Us?",
          contents: [
            "Extensive experience in sourcing EOL Tires and materials.",
            "Strong relationships with suppliers and buyers worldwide.",
            "Proven track record in facilitating efficient logistics and transportation.",
          ],
        },
        {
          title: "Global Presence",
          contents: [
            "We operate in multiple markets, including the USA, Asia, Europe.",
            "Our international presence allows us to serve a diverse range of clients.",
            "We understand the unique requirements and regulations of each market.",
          ],
        },
        {
          title: "What Sets Us Apart",
          contents: [
            "We are professional team and process-driven in our approach.",
            "We work closely with all relevant authorities to ensure smooth movement of EOL tires and various other waste materials.",
            "Our commitment to compliance and sustainability sets us apart from competitors.",
          ],
        },
      ],
      ourServices: {
        title: "OUR SERVICES",
        services: [
          {
            imgSrc: "./assets/content/services/service1.jpg",
            imgAlt: "Service 1 image",
            description:
              "E.O.L Car Passenger Tires, pressed and baled for the purpose of container loading.",
          },
          {
            imgSrc: "./assets/content/services/service2.jpg",
            imgAlt: "Service 2 image",
            description:
              "Commercial Truck Tires, 3 cut tread belt and 2 sidewalls pressed and baled for the purpose of container loading.",
          },
          {
            imgSrc: "./assets/content/services/service3.jpg",
            imgAlt: "Service 3 image",
            description:
              "50-80mm Shredded Tires, Loose container loading. 26/27 MT - 40ft HQ Container.",
          },
          {
            imgSrc: "./assets/content/services/service4.jpg",
            imgAlt: "Service 4 image",
            description:
              "Primary One Cut, Rough Shredded Tires 50-350mm. 26 MT per 40ft HQ Container. Approx; 12 containers per month. Ref: OX-UK-GE.",
          },
          {
            imgSrc: "./assets/content/services/service5.jpg",
            imgAlt: "Service 5 image",
            description:
              "10-80mm Shredded Tires. 26 MT per 40ft HQ Container. Approx; 64 containers per month. Ref; IRE-PH",
          },
        ],
      },
      ourPersonels: {
        title: "OUR TEAM",
        personels: [
          {
            imgSrc: "./assets/profiles/bb-profile.jpg",
            imgAlt: "Burcin Bilgin profile photo",
            descriptions: [
              "Burcin Bilgin",
              "Nitrox Green",
              "Mobile: +90 533 363 80 08",
              "Email: burcin@nitrox-green.de",
            ],
          },
          {
            imgSrc: "./assets/profiles/np-profile.jpg",
            imgAlt: "Nicholas Potts profile photo",
            descriptions: [
              "Nicholas Potts",
              "Smart RR",
              "UK Mobile: +44 7768 441 934",
              "USA Mobile: + 44 7879 612 987",
              "Email: nick@smart-rr.co.uk",
            ],
          },
          {
            imgSrc: "./assets/profiles/dp-profile.jpg",
            imgAlt: "Dominic Potts profile photo",
            descriptions: [
              "Dominic Potts",
              "Smart RR",
              "Mobile: +44 7877 00 69 73",
              "Whatsapp: +44 1732 247 028",
              "Email: dominic@smart-rr.co.uk",
            ],
          },
        ],
      },
    },
    plasticGarbage: {
      name: "PLASTIC GARBAGE BAG AND COMPOSIT PANELS",
    },
  },
  de: {
    scrapTire: {
      name: "ALTREIFENGESCHÄFT",
      certificateDescription:
        "Bescheinigung über die Eintragung gemäß den Verordnungen 2011 Verordnungsbehörde National Customer Contact Centre 99 Parkway Avenue, Sheffield, S9 4WF Telefonnummer 03708 506506 Die Umweltbehörde bestätigt, dass die folgenden Angaben in das von ihr gemäß Verordnung 28 der Waste (England and Wales) Regulations 2011 geführte Register eingetragen sind. Angaben zum Beförderer Name des registrierten Beförderers SMART RESOURCES RECYCLING LIMITED Eingetragen als Abfallbeförderer, -makler und -händler der oberen Klasse Registrierungsnummer CBDU139128 Geschäftsadresse 33, EDWARD STREET,RUSTHALL, ROYAL TUNBRIDGE WELLS,TN4 8RP Telefonnummer 01892670801 Datum der Registrierung 15. September 2022 Ablauf der Registrierung (sofern nicht widerrufen) 13. Oktober 2025",
      companyDescription:
        "Die im Vereinigten Königreich ansässige Smart Resources Recycling Limited ist der Partner von Nitrox-Green Company für Recyclingaktivitäten",
      definitions: [
        {
          title: "Warum uns wählen?",
          contents: [
            "Umfassende Erfahrung in der Beschaffung von EOL-Reifen und Materialien",
            "Starke Beziehungen zu Lieferanten und Käufern weltweit.",
            "Nachgewiesene Erfolgsbilanz bei der Erleichterung einer effizienten Logistik und eines effizienten Transports.",
          ],
        },
        {
          title: "Globale Präsenz",
          contents: [
            "Wir sind auf mehreren Märkten tätig, unter anderem in den USA, Asien und Europa.",
            "Unsere internationale Präsenz ermöglicht es uns, eine Vielzahl von Kunden zu bedienen.",
            "Wir verstehen die besonderen Anforderungen und Vorschriften jedes Marktes.",
          ],
        },
        {
          title: "Was uns auszeichnet",
          contents: [
            "Wir sind ein professionelles Team und arbeiten prozessorientiert",
            "Wir arbeiten eng mit allen relevanten Behörden zusammen, um einen reibungslosen Transport von EOL-Reifen und verschiedenen anderen Abfallmaterialien zu gewährleisten.",
            "Unser Engagement für Compliance und Nachhaltigkeit unterscheidet uns von unseren Mitbewerbern.",
          ],
        },
      ],
      ourServices: {
        title: "UNSERE DIENSTLEISTUNGEN",
        services: [
          {
            imgSrc: "./assets/content/services/service1.jpg",
            imgAlt: "Service 1 image",
            description:
              "E.O.L Pkw-Reifen, gepresst und zu Ballen gepresst zum Zwecke der Containerverladung.",
          },
          {
            imgSrc: "./assets/content/services/service2.jpg",
            imgAlt: "Service 2 image",
            description:
              "Gewerbliche Lkw-Reifen, 3 geschnittene Laufflächengürtel und 2 Seitenwände, gepresst und zu Ballen gepresst zum Zwecke der Containerverladung.",
          },
          {
            imgSrc: "./assets/content/services/service3.jpg",
            imgAlt: "Service 3 image",
            description:
              "50-80mm geschredderte Reifen, lose Containerladung. 26/27 MT - 40ft HQ Container.",
          },
          {
            imgSrc: "./assets/content/services/service4.jpg",
            imgAlt: "Service 4 image",
            description:
              "Primary One Cut, grob geschredderte Reifen 50-350mm. 26 MT pro 40ft HQ Container. Ca. 12 Container pro Monat. Ref: OX-UK-GE.",
          },
          {
            imgSrc: "./assets/content/services/service5.jpg",
            imgAlt: "Service 5 image",
            description:
              "10-80mm geschredderte Reifen. 26 MT pro 40ft HQ Container. Ca; 64 Container pro Monat. Ref; IRE-PH",
          },
        ],
      },
      ourPersonels: {
        title: "UNSER TEAM",
        personels: [
          {
            imgSrc: "./assets/profiles/bb-profile.jpg",
            imgAlt: "Burcin Bilgin profile photo",
            descriptions: [
              "Burcin Bilgin",
              "Nitrox Green",
              "Mobil: +90 533 363 80 08",
              "Email: burcin@nitrox-green.de",
            ],
          },
          {
            imgSrc: "./assets/profiles/np-profile.jpg",
            imgAlt: "Nicholas Potts profile photo",
            descriptions: [
              "Nicholas Potts",
              "Smart RR",
              "UK Mobil: +44 7768 441 934",
              "USA Mobil: + 44 7879 612 987",
              "Email: nick@smart-rr.co.uk",
            ],
          },
          {
            imgSrc: "./assets/profiles/dp-profile.jpg",
            imgAlt: "Dominic Potts profile photo",
            descriptions: [
              "Dominic Potts",
              "Smart RR",
              "Mobil: +44 7877 00 69 73",
              "Whatsapp: +44 1732 247 028",
              "Email: dominic@smart-rr.co.uk",
            ],
          },
        ],
      },
    },
    plasticGarbage: {
      name: "PLASTIKMÜLLSACK UND VERBUNDPLATTEN",
    },
  },
} as any;
