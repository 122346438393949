import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const LandingLayout = () => {
  return (
    <>
      <Navbar />
      <div style={{ minHeight: "calc(100vh - 384px)" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default LandingLayout;
