import { Carousel, Col, Grid, Row } from "antd";
import { categoryContstants, languageContstants } from "../../language";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SlideCrudApi } from "../../client/Api";

const { useBreakpoint } = Grid;

const MainPart = () => {
  const navigate = useNavigate();
  const selectedLanguage = useSelector((store: any) => store.selectedLanguage);
  const selectedContent = useSelector(
    (store: any) => languageContstants[store.selectedLanguage]
  );
  const screens = useBreakpoint();

  const [slides, setSlides] = useState<any[]>([]);

  useEffect(() => {
    SlideCrudApi.getAll().then((response: any) =>
      setSlides(response["hydra:member"])
    );
  }, []);

  return (
    <>
      <Carousel
        autoplay
        arrows
        effect="fade"
        style={{
          height: "calc(100vh - 90px)",
          maxHeight: screens.xs ? 300 : "none",
        }}
      >
        {slides.map((slide, index) => (
          <div key={index}>
            <Row
              justify="center"
              style={{
                position: "absolute",
                width: "100%",
                zIndex: 100,
                bottom: 30,
              }}
            >
              <Col span={12}>
                <Row>
                  <Col span={24}>
                    <Row
                      justify="center"
                      style={{
                        borderRadius: 4,
                        backgroundColor: "#fff",
                        boxShadow: "4px 4px 16px #000",
                      }}
                    >
                      <p
                        style={{
                          fontSize: screens.xs ? 16 : 24,
                          fontWeight: 600,
                        }}
                      >
                        {slide.content[selectedLanguage].title}
                      </p>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="center">
                      <p style={{ fontSize: 18 }}>
                        {slide.content[selectedLanguage].desc}
                      </p>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div
              style={{ position: "absolute", zIndex: 100, color: "white" }}
            ></div>
            <img
              style={{
                width: "100%",
                height: "calc(100vh - 90px)",
                maxHeight: screens.xs ? 300 : "none",
                objectFit: "fill",
                // opacity: 0.6,
              }}
              src={process.env.REACT_APP_UPLOAD_URL + slide.content.src}
              alt={slide.content.alt}
            />
          </div>
        ))}
      </Carousel>
      <Row id={selectedContent.categoriesId} style={{ minHeight: 600 }}>
        {Object.values(categoryContstants[selectedLanguage]).map(
          (category: any, index: number) => (
            <Col
              className="category"
              key={index}
              xs={24}
              lg={6}
              onClick={() =>
                category.isOtherLink
                  ? window.open(category.path)
                  : navigate(category.path)
              }
              style={{
                cursor: "pointer",
                height: "100%",
                transform: "scale(0.5) rotate(-10deg)",
                animationName: "upscaleAnimation",
                animationDuration: "1.5s",
                animationDelay: "0.2s",
                animationFillMode: "forwards",
              }}
            >
              <img
                src={category.src}
                alt={category.alt}
                style={{ width: "100%", height: "100%", border: "none" }}
              />
              <div
                style={{
                  width: "100%",
                  height: 225,
                  backgroundColor: category.color,
                  position: "absolute",
                  bottom: 0,
                  opacity: 0,
                  animationName: "visibilityAnimation",
                  animationDuration: "2s",
                  animationDelay: "1.5s",
                  animationFillMode: "forwards",
                }}
              >
                <Row
                  justify="center"
                  align="middle"
                  style={{ height: "100%", color: "white" }}
                >
                  <h1
                    style={{
                      padding: 8,
                      textAlign: "center",
                      fontSize: screens.xs ? 18 : 24,
                    }}
                  >
                    {category.name}
                  </h1>
                </Row>
              </div>
            </Col>
          )
        )}
      </Row>
      <Row id={selectedContent.contactId} justify="center">
        <Col span={22} style={{ margin: "1em 0px", padding: "1em" }}>
          <Row justify="space-between" align="middle">
            <Col span={24}>
              <h1 className="page-name">{selectedContent.contactUpperCase}</h1>
            </Col>
            <Col span={24}>
              <iframe
                title="unique"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2513.8981059467887!2d6.9418260375417455!3d50.944097411686904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf250833eed365%3A0x5a74d5b306b232b5!2sChristophstra%C3%9Fe%2040%2C%2050670%20K%C3%B6ln%2C%20Almanya!5e0!3m2!1str!2str!4v1705416787491!5m2!1str!2str"
                width="100%"
                height="600px"
                style={{ border: 0, backgroundColor: "#fff" }}
                loading="lazy"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MainPart;
